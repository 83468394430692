import { all, put,call} from 'redux-saga/effects'
import  getMenuData  from 'services/menu'
// import Axios from 'axios'
// import { URL } from '../../pages/app-config/index'

/* export function* GET_DATA() {
  const param = {
    query: `query {menuList(ad_user_id:"10")}`,
  }
  let menuData = []

  yield Axios.post(URL, param)
    .then(response => {
      console.log(getTopMenuData,"getTopMenuData====>>>");
      const menuItems = response.data.data.menuList[0].menu
      menuData = menuItems || []
    })
    .catch(error => {
      console.log(error)
      // eslint-disable-next-line no-debugger
      debugger
    })
 // const menuData = yield call(getTopMenuData)
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuData,
    }
  })
} */
export function* GET_DATA() {
  const menuData = yield call(getMenuData)
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuData,
    },
  })
}

export default function* rootSaga() {
  yield all([
    GET_DATA(), // run once on app load to fetch menu data
  ])
}
