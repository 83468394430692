const actions = {
  SET_STATE: 'menu/SET_STATE',
  GET_DATA: 'menu/GET_DATA',
  SELECTED_OPTION: 'SELECTED_OPTION',
}

export const changeSelectedOption = payload => {
  return {
    type: actions.SELECTED_OPTION,
    payload,
  }
}

export default actions
