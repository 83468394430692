import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import style from './style.module.scss'
import footerImg from '../../../assets/img/CW_LOGO.jpg'

const mapStateToProps = ({ settings }) => ({ settings })

@connect(mapStateToProps)
class Footer extends React.Component {
  render() {
    const {
      settings: { isContentNoMaxWidth },
    } = this.props
    return (
      <div
        className={classNames(style.footer, {
          [style.footerFullWidth]: isContentNoMaxWidth,
        })}
      >
        <div className={style.inner}>
          {/* <div className="row">
            <div className="col-md-12">
              <p><img src="resources/images/air-logo.png" alt="Air UI" />
                <strong>Copyright © 2020 CWERP Solutions Pvt Ltd | CW Back Office.</strong>
              </p>
            </div>
            <div className="col-md-4">
              <div className={style.logo}>
                <img src="resources/images/air-logo.png" alt="Air UI" />
                <div className={style.logoName}>AIR UI</div>
              </div>
            </div>
          </div> */}
          <div className="row">
            <div className="col-sm-12">
              <strong className={style.footerServices}>
                <img
                  src={footerImg}
                  rel="cw solutions"
                  alt="cw solutions"
                  height="35px"
                />
              </strong>
              <div className={style.footerServicesChildLogo}>
                <div><span>Copyright © 2020 CWERP Solutions Pvt Ltd | CW Back Office.</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
