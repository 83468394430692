import React from 'react'
import { injectIntl } from 'react-intl'
import { Select } from 'antd'
import { connect } from 'react-redux'
import { Link} from 'react-router-dom'
import { changeSelectedOption } from '../../../../redux/menu/actions'
import styles from './style.module.scss'

const { Option, OptGroup } = Select;
const mapStateToProps = ({ menu }) => ({
  menuData: menu.menuData
})

@connect(mapStateToProps)
@injectIntl
class Search extends React.Component {

  toSendRequest = (windowid) => {
   
    const { dispatch } = this.props;
    console.log(this.props, "props===>");
    if(windowid){
      dispatch(changeSelectedOption({ selectedOption: windowid }))
    }else{
      console.log("NO WINDOW")
    }
 
  }

  toSendRequest1=(value, event)=>{

    if(event.key==null){
      const ToLink=event.props.children.props.to;
      window.location =`/#${ToLink}`
    }else{
      this.toSendRequest(event.key)
      window.location ='/#/windows/windowList'
    } 
  }

  render() {
    const { menuData } = this.props;
    const menuList = menuData.map(menu => (
      <OptGroup label={<span style={{ fontSize: '13px', color: 'black' }}><strong>{menu.title}</strong></span>}>
        {menu.children.map(sub => {
          return (
            sub.children.map(subSubMenu => {
              return (
                <Option
                  key={subSubMenu.windowid}
                  value={subSubMenu.title}
                  onClick={() => this.toSendRequest(subSubMenu.windowid,subSubMenu.url)}
                >
                  <Link to={subSubMenu.url}>
                    <span>{subSubMenu.title}</span>
                  </Link>
                </Option>
              )
            })
          )

        })}
      </OptGroup>
    ))
    return (
      <Select
        showSearch
       // allowClear
        showArrow={false}
        style={{ width: 270 }}
        placeholder={<span className="style_searchInput__1uxJS"><i className={`${styles.searchIcon} fe fe-search`} /> Start typing to search...</span>}
        onChange={this.toSendRequest1}

      >

        {menuList}
      </Select>
    )
  }
}

export default Search