
 const initialState = {
  usersDetails:'' 
} 

export default function productListView( state = initialState, action) {
  console.log(action,"action.type====>productList",action.payload)
if(action.type==='Users_Details'){
    return {
      ...state,
      usersDetails:action.payload,
    }
  }
  return state; 

}


