export default async function getMenuData() {
  return [
   
     {
      title: 'Sales',
      key: 'sales',
      children: [
        {
          title: 'Transactions',
          windowname:'transactions',
         // key:'transactions',
          children: [{
            title: 'Sales Order',
            windowid:'143',
            windowname:'Sales Order',
           url:'/windows/windowList',
           key:'salesorder',
          },
          {
            title: 'Sales Error',
            url: '/sales/saleserror/listSalesError',
            key:'saleserror'
          },]
        },
        {
          title: 'Setup',
          windowname:'setup',
         // key:'setup',
          children: [{
            title: 'Configurations',
            windowid:'63B5CBCC04FE489685D3A6A158134E67',
            windowname:'configurations',
           url:'/windows/windowList',
           key:'configurations',
          },
          {
            title: 'Region',
            windowid:'04F870D3D01A47E9BDC67DB084553CDC',
            windowname:'region',
            url:'/windows/windowList',
            key:'region'
          },
            {
            title: 'Promotions',
            windowid:'83E8B66089D24031B95A36CECA1C7E94',
            windowname:'promotions',
            url:'/windows/windowList',
            key:'promotions'
          },]
        },
        {
          title: 'Reports',
          windowname:'reports',
         // key:'reports',
          children: [{
            title: 'Sales Summary',
            windowid:'',
            windowname:'salessummary',
           url:'/windows/windowList',
           key:'salessummary',
          },
          {
            title: 'Sales By Product ',
            windowid:'',
            windowname:'salesbyproduct ',
           url:'/windows/windowList',
           key:'salesbyproduct',
          },
            {
            title: 'Sales By Payment Method',
            windowid:'',
            windowname:'salesbyPaymentMethod ',
           url:'/windows/windowList',
           key:'salesbyPaymentMethod',
          },]
        }
      ]
    },

    {
      title: 'Inventory',
      key: 'inventory',
      children: [
        {
          title: 'Transactions',
          windowname:'transactions',
         // key:'transactions',
          children: [{
            title: 'Physical Inventory',
            url : '/CW/inventory/physicalInventory/listPhysicalInventory',
            key:'PhysicalInventory'
          },
          {
            title: 'Goods Movement',
            url:'/CW/inventory/goodsMovement/listGoodsMovement',
            key:'GoodsMovement'
          },]
        },
        {
          title: 'Stock Transfers',
          windowname:'stocktransfers ',
         // key:'stocktransfers',
          children: [ {
            title: 'Stock Transfer Request',
            url:'/stock/listStockTransferRequest',
            key:'StockTransferRequest'
          },
          {
            title: 'Stock Transfer Order',
            url:'/stock/listStockTransferOrder',
            key:'StockTransferOrder'
          },
          {
            title: 'Stock Transfer Issue',
            url:'/stock/listStockTransferIssue',
            key:'StockTransferIssue'
          },
          {
            title: 'Stock Transfer Receipt',
            url:'/stock/listStockTransferReceipt',
            key:'StockTransferReceipt'
          },
          {
            title: 'Stock Tansfer Configuration',
            url:'/stock/stockTransferConfiguration',
            key:'StockTansferConfiguration'
          },]
        },
        {
          title: 'Setup',
          windowname:'setup ',
        //  key:'setup',
          children: [{
            title: 'Warehouse and Storage Bins',
            windowid:'139',
            windowname:'Warehouse and Storage Bins',
            url:'/windows/windowList',
            key:'WarehouseandStorageBins'
          },
  
          {
            title: 'Costing Rules',
            windowid:'3E945A9102144C16BD211D211089C2DF',
            windowname:'Costing Rules',
            url:'/windows/windowList',
            key:'CostingRules'
          },
          {
            title: 'Landed Cost Type',
            windowid:'944C49CE80BE4F71B9917BD680A052A8',
            windowname:'Landed Cost Type',
            url:'/windows/windowList',
            key:'LandedCostType'
          }]
        },
        {
          title: 'Reports',
          windowname:'reports ',
         // key:'reports',
          children: [ {
            title: 'Traceability Report',
            windowid:'',
            windowname:'traceabilityreport',
            url:'/windows/windowList',
            key:'traceabilityreport'
          },
            {
            title: 'Stock Report',
            windowid:'',
            windowname:'stockreport',
            url:'/windows/windowList',
            key:'stockreport'
          },
           {
            title: 'Product Movement Report',
            windowid:'',
            windowname:'productmovementreport',
            url:'/windows/windowList',
            key:'productmovementreport'
          }]
        }
          ]
    
    },

  

    {
      title: 'Production',
      key: 'production',
      children: [
        {
          title: 'Transactions',
         
          windowname:'transactions',
         
          key:'transactions',
          children: [{
            title: 'Production Order',
            windowid:'27ED0781309D4555B96BC1BA74379660',
            windowname:'Production Order',
            url:'/windows/windowList',
            key:'ProductionOrder'
          },
          {
            title: 'Internal Consumption',
            windowid:'800076',
            windowname:'Internal Consumption',
            url:'/windows/windowList',
            key:'InternalConsumption'
          }, ]
        },
        {
          title: 'Reports',
         
          windowname:'reports',
         
          key:'reports',
          children: [   {
            title: 'Production Report',
            windowid:'',
            windowname:'productionreport',
            url:'/windows/windowList',
            key:'productionreport'
          }]
        }
      ]
      },
  

    {
      title: 'Purchase',
      key: 'purchase',
      children: [
        {
          title: 'Transactions',
         
          windowname:'transactions',
         
          key:'transactions',
          children: [ {
            title: 'Purchase Order',
            url:'/purchase/purchaseOrder',
            key:'PurchaseOrder'
          },
          {
            title: 'Goods Receipts',
            url:'/purchase/goodsReceipts',
            key:'GoodsReceipts'
          },
    
          {
            title: 'Purchase Invoice',
          //  url:'/purchase/purchaseInvoice',
            key:'PurchaseInvoice',
            windowid:'183',
            windowname:'purchaseorderreport',
            url:'/windows/windowList',
          },]
        },
        {
          title: 'Reports',
         
          windowname:'reports',
         
          key:'reports',
          children: [  {
            title: 'Purchase Order Report',
            windowid:'',
            windowname:'purchaseorderreport',
            url:'/windows/windowList',
            key:'purchaseorderreport'
          },
           {
            title: 'GRN Dimensional Report',
            windowid:'',
            windowname:'goodsreceiptsdimensionalreport',
            url:'/windows/windowList',
            key:'goodsreceiptsdimensionalreport'
          },
           {
            title: 'Invoice Dimensional Report',
            windowid:'',
            windowname:'purchaseinvoicedimensionalreport',
            url:'/windows/windowList',
            key:'purchaseinvoicedimensionalreport'
          },
        {
            title: 'Purchase Dimensional Report',
            windowid:'',
            windowname:'purchasedimensionalreport',
            url:'/windows/windowList',
            key:'purchasedimensionalreport'
          },]
        }
      
      ]
      },
  

    {
      title: 'Finance',
      key: 'finance',
      children: [
        {
          title: 'Transactions',
         
          windowname:'transactions',
         
          key:'transactions',
          children: [ {
            title: 'Payment Out',
            windowid:'6F8F913FA60F4CBD93DC1D3AA696E76E',
            windowname:'Payment Out',
            url:'/windows/windowList',
            key:'paymentOut'
          },
  
          {
            title: 'Payment IN',
            windowid:'E547CE89D4C04429B6340FFA44E70716',
            windowname:'Payment IN',
            url:'/windows/windowList',
            key:'paymentIN'
          },
          {
            title: 'Journal Voucher',
            windowid:'B917E8A7B0864ACEA9D941E3B7494E53',
            windowname:'Journal Voucher',
            url:'/windows/windowList',
            key:'journalVoucher'
          },
          {
            title: 'Financial Account',
            windowid:'94EAA455D2644E04AB25D93BE5157B6D',
            windowname:'Financial Account',
            url:'/windows/windowList',
            key:'financialAccount'
          }]
        },

        {
          title: 'Setup',
         
          windowname:'setup',
         
          key:'setup',
          children: [ {
            title: 'Payment Method',
            windowid:'3CAAC7D54593489384452416ACF356DD',
            windowname:'Payment Method',
            url:'/windows/windowList',
            key:'paymentMethod'
          },
          {
            title: 'Tax Rate',
            windowid:'137',
            windowname:'Tax Rate',
            url:'/windows/windowList',
            key:'taxRate'
          },
          {
            title: 'Tax Category',
            windowid:'138',
            windowname:'Tax Category',
            url:'/windows/windowList',
            key:'taxCategory'
          },
          {
            title: 'G/L Item',
            windowid:'800007',
            windowname:'G/L Item',
            url:'/windows/windowList',
            key:'G/LItem'
          },
          {
            title: 'Fiscal Calendar',
            windowid:'117',
            windowname:'Fiscal Calendar',
            url:'/windows/windowList',
            key:'fiscalCalendar'
          },
          {
            title: 'G/L Category',
            windowid:'131',
            windowname:'G/L Category',
            url:'/windows/windowList',
            key:'G/LCategory'
          },
          {
            title: 'Open/Close Period',
            windowid:'E66E701CCBA14B8BA480CBDE37C50D7A',
            windowname:'Open/Close Period',
            url:'/windows/windowList',
            key:'Open/ClosePeriod'
          },
          {
            title: 'Document Type',
            windowid:'135',
            windowname:'Document Type',
            url:'/windows/windowList',
            key:'DocumentType'
          },
          {
            title: 'Document Sequence',
            windowid:'112',
            windowname:'Document Sequence',
            url:'/windows/windowList',
            key:'DocumentSequence'
          },
          
          
          {
            title: 'Chart of Accounts',
            windowid:'118',
            windowname:'Chart of Accounts',
            url:'/windows/windowList',
            key:'ChartofAccount'
          },
          {
            title: 'General Ledger Configuration',
            windowid:'125',
            windowname:'General Ledger Configuration',
            url:'/windows/windowList',
            key:'GeneralLedgerConfiguration'
          } ]
        },
        {
          title: 'Reports',
         
          windowname:'reports',
         
          key:'reports',
          children: [  {
            title: 'Payment Report',
            windowid:'',
            windowname:'paymentreport',
            url:'/windows/windowList',
            key:'paymentreport'
          },
           {
            title: 'Cashflow Report',
            windowid:'',
            windowname:'cashflowreport',
            url:'/windows/windowList',
            key:'cashflowreport'
          },
           {
            title: 'Balance Sheet',
            windowid:'',
            windowname:'balancesheet',
            url:'/windows/windowList',
            key:'balancesheet'
          },

           {
            title: 'Trail Balance',
            windowid:'',
            windowname:'trailbalance',
            url:'/windows/windowList',
            key:'trailbalance'
          },
          {
            title: 'General Ledger',
            windowid:'',
            windowname:'generalledger',
            url:'/windows/windowList',
            key:'generalledger'
          },
           {
            title: 'Journal Entries Report',
            windowid:'',
            windowname:'journalentriesreport',
            url:'/windows/windowList',
            key:'journalentriesreport'
          },
            {
            title: 'Receivable Aging Schedule',
            windowid:'',
            windowname:'receivableagingschedule',
            url:'/windows/windowList',
            key:'receivableagingschedule'
          },
           {
            title: 'Payable Aging Schedule',
            windowid:'',
            windowname:'payableagingschedule',
            url:'/windows/windowList',
            key:'payableagingschedule'
          }]
        }
        
      ]
      },


    {
      title: 'Masters',
      key: 'masters',
      children: [
        {
          title: 'Business Partner Setup',
         
          windowname:'businesspartnersetup',
         
          key:'businesspartnersetup',
          children: [  {
            title: 'Business Partner',
            windowid:'123',
            windowname:'Business Partner',
            url:'/windows/windowList',
            key:'businessPartner'
          },
  
          {
            title: 'Business Partner Category',
            windowid:'192',
            windowname:'Business Partner Category',
            url:'/windows/windowList',
            key:'BusinessPartnerCategory'
          },
  
            {
            title: 'Payment Terms',
            windowid:'141',
            windowname:'Payment Terms',
            url:'/windows/windowList',
            key:'paymentterms'
          },]
        },
        {
          title: 'Customer Setup',
         
          windowname:'customersetup',
         
          key:'customersetup',
          children: [{
            title: 'Customer',

            url: '/setup/listCustomer',
            key:'customer'
          },]
        },
        {
          title: 'Product Setup',
         
          windowname:'productsetup',
         
          key:'productsetup',
          children: [  {
            title: 'Product',
            url: '/products/productsList',
            key:'product'
          },
          {
            title: 'Product Catalogue',
            url: '/products/listCatalog',
            key:'ProductCatalogue'
          },
  
   {
            title: 'Product Category',
            windowid:'144',
            windowname:'Product Category',
            url:'/windows/windowList',
            key:'productcategory'
          },
  
    {
            title: 'Attribute',
            windowid:'260',
            windowname:'Attribute',
            url:'/windows/windowList',
            key:'attribute'
          },
  
  {
            title: 'Attribute Set',
            windowid:'256',
            windowname:'Attribute Set',
            url:'/windows/windowList',
            key:'attributeSet'
          },
  
  {
            title: 'Brand',
            windowid:'11A2976F05D841EFAE654A8B83EB4204',
            windowname:'Brand',
            url:'/windows/windowList',
            key:'brand'
          },
  {
            title: 'Unit of Measure',
            windowid:'120',
            windowname:'Unit of Measure',
            url:'/windows/windowList',
            key:'unitofMeasure'
          },]
        },
      ]
    },



    {
      title: 'Setup',
      key: 'setup',
      children: [
        {
          title: 'General Setup',
         
          windowname:'generalsetup',
         
          key:'generalsetup',
          children: [
            {
              title: 'Company',
             
              url: '/CW/setup/company/listCompany',
              key:'company'
            },
            {
              title: 'Business Unit',
              url: '/CW/setup/bussiness',
              key:'businessunit'
            },
            {
              title: 'Client',
              windowid:'109',
              windowname:'Client',
              url:'/windows/windowList',
              key:'client'
            },
            {
              title: 'Currency',
              windowid:'115',
              windowname:'Currency',
              url:'/windows/windowList',
              key:'currency'
            },
            {
              title: 'Country and Region',
              windowid:'122',
              windowname:'Country and Region',
              url:'/windows/windowList',
              key:'CountryandRegion'
            },
            {
              title: 'Region',
              windowid:'04F870D3D01A47E9BDC67DB084553CDC',
              windowname:'Region',
              url:'/windows/windowList',
              key:'region'
            },
     
          ]
        },
        {
          title: 'Retail Setup',
         
          windowname:'retailsetup',
         
          key:'retailsetup',
        children: [
          {
            title: 'Till',
            url: '/CW/setup/till',
            key:'till'
          },
          {
            title: 'Configurations',
            windowid:'63B5CBCC04FE489685D3A6A158134E67',
            windowname:'Configurations',
            url:'/windows/windowList',
            key:'configurations'
          },
          {
            title: 'Promotions',
            windowid:'83E8B66089D24031B95A36CECA1C7E94',
            windowname:'Promotions',
            url:'/windows/windowList',
            key:'promotions'
          },
      ]
    },
    {
      title: 'Security',
     
      windowname:'security',
     
      key:'security',
    children: [
      {
        windowid:'',
        windowname:'',
        title: 'Users',
        url: '/setup/listUsers',
        key:'users'
      },
      {
        title: 'Role',
        windowid:'111',
        windowname:'Role',
        url:'/windows/windowList',
        key:'role'
      },
  ]
},
        
      
  ],
  },

 
  ]
}
