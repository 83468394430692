
 const initialState = {
  salesErrorDetails:'' 
} 

export default function tillListView( state = initialState, action) {
  console.log(action,"action.type====>productList",action.payload)
if(action.type==='SalesError_Details'){
    return {
      ...state,
      salesErrorDetails:action.payload,
    }
  }
  return state; 

}


