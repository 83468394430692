// import { stat } from "fs";

 // import {productListView} from './actions' 

const initialState = {
  windowDetails:''
  }

export default function productListView(state = initialState, action) {
  console.log(action,"action.type====>Now Tomorrow Target",action.payload)
 console.log(action.type,"Action Type Chk in Sales Invoice")
   if(action.type==='DISPLAY_WINDOW_DETAILS'){
    return {
      ...state,
      records:action.payload,
      windowDetails:action.payload
    
    }
  }
  
  return state
}